//We'll use this page for a coach to come in and register their team.
import React, { useState, useEffect, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import Card from '../UIElements/Card'
import './Info.css'
import { AuthContext } from '../../context/auth-context'
import LoadingSpinner from '../UIElements/LoadingSpinner'
import Footer from '../UIElements/Footer'
import HomeNavLinks from './HomeNavLinks'
import InfoHomeNavigation from '../../../players/components/InfoHomeNavigation'
import Dec24 from './../../components/documents/Meeting Minutes Dec 18 2024.pdf'
import Feb25 from './../../components/documents/Meeting Minutes Feb 19 2025.pdf'
import Mar25 from './../../components/documents/Meeting Minutes Mar 27 2025.pdf'

//import Dec24_2 from './../../../../src/meetingMinutes/12-23-2024.pdf'

const Info = () => {
	const rulesURL =
		'https://usaballhockey.com/wp-content/uploads/2024/11/USA-Ball-Hockey-Official-Rule-Book-Revised-07.06.2021.pdf'

	return (
		<div style={{ overflowX: 'hidden' }}>
			<React.Fragment>
				<InfoHomeNavigation />
				<div
					style={{
						marginTop: '5rem',
						marginBottom: '3rem',
						display: '-webkit-box',
					}}
				>
					{/* <NavLink
						to={PBHL_BYLAWS}
						target='_blank'
						rel='noreferror'
						className='infoLink'
					>
						BY-LAWS
					</NavLink> */}
				</div>
				<div style={{ display: '-webkit-box' }}>
					<NavLink to={rulesURL} target='blank' className='infoLinkSmall'>
						USA Ball Hockey Official Rule Book
					</NavLink>
				</div>
				<div style={{ marginBottom: '6rem', display: '-webkit-box' }}>
					<NavLink
						to={'https://www.pbhl.org/'}
						target='blank'
						className='infoLinkSmall'
					>
						PBHL Website
					</NavLink>
				</div>
				<div className='underline-blue mb-4'></div>
				<h1
					className='displayProperty'
					style={{
						color: '#410009',
						marginBottom: '1.75rem',
						marginTop: '3rem',
					}}
				>
					Meeting Minutes:
				</h1>
				<div style={{ marginBottom: '3rem', display: '-webkit-box' }}>
					<NavLink to={Mar25} target='blank' className='infoLink'>
						March 27, 2025
					</NavLink>
				</div>
				<div style={{ marginBottom: '3rem', display: '-webkit-box' }}>
					<NavLink to={Feb25} target='blank' className='infoLink'>
						February 19, 2025
					</NavLink>
				</div>
				<div style={{ marginBottom: '3rem', display: '-webkit-box' }}>
					<NavLink to={Dec24} target='blank' className='infoLink'>
						December 18, 2024
					</NavLink>
				</div>

				<div style={{ marginTop: '21rem' }}>
					<Footer />
				</div>
			</React.Fragment>
		</div>
	)
}

export default Info
